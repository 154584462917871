export default {
  NODE_ENV: "production",

  // end points
  BASE_URL: "https://crewmen-prod.ts.r.appspot.com/api/v1",
  EP_GET_SUBSCRIBE_PLANS: "/subscription/getSubscriptionPlans",
  EP_GET_SUBSCRIPTION_FOR_MANAGE: "/subscription/getSubscriptionPlansForManage",
  EP_APPLY_PLANS_COUPON: "/subscription/applyPlanCoupon",
  EP_CREATE_SUBSCRIPTION: "/subscription/createPlanSubscription",
  EP_CANCEL_SUBSCRIPTION: "/subscription/cancelPlanSubscription",
  EP_RESUBSCRIBE_SUBSCRIPTION: "/subscription/resubscribePlanSubscriptionPP",
  EP_UPDATE_SUBSCRIPTION: "/subscription/updatePlanSubscriptionPP",

  // URLs
  CONTINUE_URL: "https://crewmen.page.link/PsgF",

  STRIPE_PK:
    "pk_live_51MSwDVJumdJUswCdXqwRLumKOIPMowNrla1ZieVYVoP1PlwawVg612jH93KMuBqgMbblyWcwxCGXk4k6o1Sx7Z8X00jX6J8EFH",
};
